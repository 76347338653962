<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">SMS Logs</div>
        <div class="
        col-lg-7 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
            <div class="service-plan-box-outer">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="call-label">Total Charges : </div>
                    <div class="call-count ps-1">
                        <span v-if="!loading">{{ totalsmscharges ? totalsmscharges : 0 }}</span>
                        <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-icon-loader-btn
                                  " role="status" v-if="loading">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" @click="smsLogFilterModalOpen()" class="header-filter-btn-outer btn btn-light"
                title="Filter">
                <span class="custom-filter-apply-alert"></span>
                <i class="pi pi-filter-fill custom-text-primary" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
        <DataTable :value="smsLogsList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex" :paginator="true"
            :rows="10" :lazy="true" :rowHover="true" dataKey="ld1" :totalRecords="totalRecords" @page="changePage($event)"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading">
            <template v-if="!loading" #empty>No records found.</template>
            <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
            <Column field="datatime" header="Date Time" headerStyle="width: 18%" bodyStyle="width: 18%">
                <template #body="{ data }">
                    <div>
                        {{ format_datetime(data.ld8) }}
                    </div>
                </template>
            </Column>
            <Column field="message" header="Message" headerStyle="width: 47%" bodyStyle="width: 47%">
                <template #body="{ data }">
                    <div class="p-text-left">
                        <span v-if="!activate_read_key_arr.includes(data.ld1)">{{ data.ld11.slice(0, 135).replaceAll('*',
                            '') }} </span>
                        <a v-if="data.ld11.length >= 135 && !activate_read_key_arr.includes(data.ld1)"
                            @click="activateReadMore(data.ld1)" class="showmorebody ps-1">read more</a>
                        <span v-if="activate_read_key_arr.includes(data.ld1)" v-html="data.ld11.replaceAll('*', '')"></span>
                        <span>
                            <a v-if="data.ld11.length >= 135 && activate_read_key_arr.includes(data.ld1)"
                                @click="deactivateReadMore(data.ld1)" class="showlessbody ps-1">read less</a>
                        </span>
                    </div>
                </template>
            </Column>
            <Column field="smscount" header="SMS Count" headerStyle="width: 12%" bodyStyle="width: 12%">
                <template #body="{ data }">
                    <div>
                        {{ data.ld24 ? data.ld24 : "0" }}
                    </div>
                </template>
            </Column>
            <Column field="charges" header="Charges" headerStyle="width: 12%" bodyStyle="width: 10%">
                <template #body="{ data }">
                    <span>{{ data.ld31 ? data.ld31 : "0" }}</span>
                </template>
            </Column>
            <Column field="status" header="Status" headerStyle="width: 16%" bodyStyle="width: 16%">
                <template #body="{ data }">
                    <div>
                        <span v-if="data.ld30 == 1" class="status-active">Free of Cost</span>
                        <span v-if="data.ld30 == 2" class="status-inactive">Chargeble</span>
                        <span v-if="data.ld30 == '' && data.ld30 == null">-</span>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
    <!-- filter start here -->
    <div class="modal-mask" v-if="smslogfiltermodalsflag">
        <div class="
        modal-sm modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer
      ">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Filter By</h5>
                    <button type="button" class="btn-close" @click="smsLogFilterModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">From Date</label>
                                <Calendar v-model="fromdatesms" :showIcon="true" class="custom-ultima-calendar"
                                    placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :maxDate="todaysDate"
                                    :manualInput="false" :monthNavigator="true"
                                    :yearRange="'1990:' + new Date().getFullYear()" appendTo="body" :yearNavigator="true" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">To Date</label>
                                <Calendar v-model="todatesms" :disabled="fromdatesms == null" :showIcon="true"
                                    class="custom-ultima-calendar" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                                    :minDate="fromdatesms" :maxDate="todaysDate" :manualInput="false" :monthNavigator="true"
                                    :yearRange="'1990:' + new Date().getFullYear()" appendTo="body" :yearNavigator="true" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Charges Status</label>
                                <div class="custom-group-radio-box-btn">
                                    <input type="radio" class="btn-check" v-model="ld30" name="charges-option" id="freecost"
                                        autocomplete="off" value="1" />
                                    <label class="btn custom-radio-box-btn text-capitalize" for="freecost">Free of
                                        Cost</label>
                                    <input type="radio" class="btn-check" v-model="ld30" name="charges-option"
                                        id="chargeble" autocomplete="off" value="2" />
                                    <label class="btn text-capitalize" for="chargeble">Chargeble</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-7 col-md-7 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Mobile No.</label>
                                <div class="input-group custom-input-group">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <Multiselect v-model="countrycode" :options="countryCodeList" :searchable="true"
                                            label="label" placeholder="Select" disabled
                                            class="multiselect-custom custom-title-multiselect" :canClear="false"
                                            :closeOnSelect="true" :object="true" noOptionsText="No Result"
                                            :canDeselect="false" />
                                    </div>
                                    <div class="col-lg-8 col-md-8 col-12">
                                        <input type="text" v-model="ld10" class="form-control" id="formMobileNoInput"
                                            placeholder="Enter mobile No." maxlength="10" @keypress="onlyNumberMobile"
                                            autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 m-0">
                        <div class="d-flex justify-content-between">
                            <button class="btn filter-apply-btn"
                                :disabled="fromdatesms == null && todatesms == null && ld30 == '' && ld10 == ''" @click="
                                    btnFilterSearch(ld30, ld10)
                                    ">
                                Apply Filter
                            </button>
                            <button class="btn btn-link filter-reset-btn" @click="resetVoterFilter">
                                Reset All
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- filter end here -->
</template>
<script>
import ApiService from "../../../service/ApiService";
import commonMixin from "../../../mixins/commonMixin.js";
import moment from 'moment';
export default {
    mixins: [commonMixin],
    data() {
        return {
            smsLogsList: null,
            totalRecords: 0,
            page_no: 0,
            loading: false,
            activate_read_key_arr: [],
            smslogfiltermodalsflag: false,
            todaysDate: new Date(),
            fromdatesms: null,
            todatesms: null,
            ld30: '',
            ld10: '',
            countryCodeList: [],
            countrycode: { value: 101, label: "+91" },
            totalsmscharges: 0,
        };
    },
    mounted() {
        this.getTransactionalSmsLogs({
            page_no: this.page_no, fromdatesms: this.fromdatesms,
            todatesms: this.todatesms,
            ld30: this.ld30,
            ld10: this.ld10
        });
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    methods: {
        activateReadMore(e) {
            this.activate_read_key_arr.push(e);
        },

        deactivateReadMore(e) {
            var index = this.activate_read_key_arr.indexOf(e);
            if (index !== -1) {
                this.activate_read_key_arr.splice(index, 1);
            }
        },
        getTransactionalSmsLogs(e) {
            this.loading = true;
            this.activate_read_key_arr = [];
            this.ApiService.getTransactionalSmsLogs(e).then((items) => {
                if (items.status == 200) {
                    this.smsLogsList = items.data;
                    this.totalRecords = items.count;
                    this.totalsmscharges = items.totalCharges;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.smsLogsList = null;
                    this.totalRecords = 0;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getTransactionalSmsLogs({
                page_no: this.page_no, fromdate: this.fromdatesms,
                todate: this.todatesms,
                ld30: this.ld30,
                ld10: this.ld10
            });
        },
        format_datetime(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return 'N/A';
            }
        },
        smsLogFilterModalOpen() {
            this.smslogfiltermodalsflag = true;
            if (this.fromdatesms != null) {
                this.fromdatesms = new Date(this.fromdatesms);
            }
            if (this.todatesms != null) {
                this.todatesms = new Date(this.todatesms);
            }
        },
        smsLogFilterModalClose() {
            this.smslogfiltermodalsflag = false;
        },
        btnFilterSearch(ld30, ld10
        ) {
            if (this.fromdatesms != null && this.todatesms != null) {
                this.fromdatesms = moment(this.fromdatesms).format('YYYY-MM-DD');
                this.todatesms = moment(this.todatesms).format('YYYY-MM-DD');
            }
            this.ld30 = ld30;
            this.ld10 = ld10;
            this.smslogfiltermodalsflag = false;
            this.getTransactionalSmsLogs({
                page_no: this.page_no,
                fromdate: this.fromdatesms,
                todate: this.todatesms,
                ld30: this.ld30,
                ld10: this.ld10,
            });
        },
        resetVoterFilter() {
            this.fromdatesms = null;
            this.todatesms = null;
            this.ld30 = '';
            this.ld10 = '';
            this.getTransactionalSmsLogs({
                page_no: this.page_no,
            });
            this.smslogfiltermodalsflag = false;
        },
        onlyNumberMobile($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }

            var mobileInput = document.getElementById("formMobileNoInput");
            if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
                $event.preventDefault();
            }
        },
    },
};
</script>
<style scoped>
.status-active {
    background: #c8e6c9;
    border-radius: 2px;
    letter-spacing: 0.3px;
    color: #256029;
    opacity: 1;
    font-size: 12px;
    font-family: "AcuminPro-Bold";
    padding: 0.3em 0.2rem 0.2em 0.2rem;
    text-transform: capitalize;
    border: 1px solid #4daa53;
    line-height: 12px;
}

.status-inactive {
    background: #ffcdd2;
    border-radius: 2px;
    letter-spacing: 0.3px;
    color: #c63737;
    opacity: 1;
    font-size: 12px;
    font-family: "AcuminPro-Bold";
    padding: 0.3em 0.2rem 0.2em 0.2rem;
    text-transform: capitalize;
    border: 1px solid #d7a5a5;
    line-height: 12px;
}

.showmorebody {
    color: #3f51b5 !important;
    cursor: pointer;
}

.showlessbody {
    color: #3f51b5 !important;
    cursor: pointer;
}

.service-plan-box-outer {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #e0e5ed;
    border-radius: 4px;
    padding: 6px 10px;
}

.service-plan-box-outer .call-label {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
    user-select: none;
}

.service-plan-box-outer .call-count {
    font-family: "AcuminPro-SemiBold";
    font-size: 16px;
    letter-spacing: 0.32px;
    color: #4a5463;
    text-align: left;
    line-height: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
</style>